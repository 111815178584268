const scrollHide = calcScroll();

function calcScroll() {
    let div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
}

function hideScroll() {
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = `${scrollHide}px`;
}

function showScroll() {
    document.body.style.overflow = "";
    document.body.style.marginRight = '';
}

function showModal(modalItem) {
    const modal = document.querySelector(modalItem);
    modal.classList.add('active');
    hideScroll();
}


//////////////////////////////////////////////////////////////////////////////

function closeModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');

    modalAll.forEach(item => {
        item.classList.remove('active');
        showScroll();
    });

}

function closeAllModal() {
    const modalAll = document.querySelectorAll('.modal-wrap');
    const modalClose = document.querySelectorAll('.modal__close');

    modalClose.forEach(item => {
        item.addEventListener('click', function () {
            closeModal();
        });
    });

    modalAll.forEach(item => {
        item.addEventListener('click', function (e) {
            if (e.target === item) {
                item.classList.remove('active');
                showScroll();
            }
        });
    });
}

closeAllModal();


/////////////////////////////////////////////////////////////////////////


function modalService() {
    const modalTrigger = document.querySelectorAll('.management-item__btn');
    const modalClose = document.querySelectorAll('.modal-management__close');
    const modalAll = document.querySelectorAll('.modal-wrap-management');

    modalTrigger.forEach(item => {
        item.addEventListener("click", function () {
            let currentBtn = item;
            let tabId = currentBtn.getAttribute("data-modal");
            let currentModal = document.querySelector(tabId);

            currentModal.classList.add('active');
            hideScroll();
        });
    });

    function closeModal() {
        modalAll.forEach(item => {
            item.classList.remove('active');
        });
        showScroll();
    }

    modalAll.forEach(item => {
        item.addEventListener('click', function (e) {
            if (e.target === item) {
                closeModal();
            }
        });
    });

    modalClose.forEach(item => {
        item.addEventListener('click', function () {
            closeModal();
        });
    });

}

if (document.querySelector('.management')) {
    modalService();
}






