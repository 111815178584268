document.addEventListener('DOMContentLoaded', function () {

    $('.tab').on('click', function (event) {
        event.preventDefault();

        $($(this).siblings()).removeClass('tab-active');
        $($(this).closest('.tabs-wrapper').siblings().find('div')).removeClass('tabs-content-active');

        $(this).addClass('tab-active');
        $($(this).attr('href')).addClass('tabs-content-active');
    });

    $('.open-data__btn').on('click', function () {
        $('.open-data').addClass('hide');
        $(this).remove();
        $('.more-statistic').slideToggle(300);
    });

    $('.accardion__item .accardion__item--title').on('click', function () {
        $('.accardion__item .accardion__item--descr').not($(this).next()).slideUp(300);
        $(this).next().slideToggle(300);
        $(this).toggleClass('active');
        $(this).parents('.accardion__item').siblings().find('div').removeClass('active');
    });


    $('.radio-block').on('click', function () {
        $(this).find('input').prop("checked", true);
        $(this).siblings().find('input').prop("checked", false);
        if ($(this).find('input').prop("checked", true)) {
            $(this).toggleClass('active');
            $(this).siblings().removeClass('active');
        }
    });

    $("#phone").intlTelInput();

    $('.input-date').on('input', function () {
        $(this).parents('.data-block').find('.data-block__val').text($(this).val());
    });

    $('.burger').on('click', function () {
        $('.burger, .nav').toggleClass('active');
        $('body').toggleClass('scroll-hide');
    });

    const swiper = new Swiper(".partners-swiper", {
        slidesPerView: 5,
        spaceBetween: 50,
        loop: true,
        speed: 1000,
        breakpoints: {
            1450: {
                slidesPerView: 5,
            },
            1150: {
                slidesPerView: 4,
            },
            740: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            300: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });

    const gallerySwiper = new Swiper(".gallery-swiper", {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: true,
        speed: 500,
        navigation: {
            nextEl: ".gallery .swiper-button-next",
            prevEl: ".gallery .swiper-button-prev",
        },
        breakpoints: {
            1020: {
                slidesPerView: 5,
            },
            760: {
                slidesPerView: 4,
            },
            550: {
                slidesPerView: 3,
            },
            300: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });


    const ctx = document.getElementById('my-chart').getContext('2d');

    const myChart = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: [
                'Всего населения',
                'Покинувшие республику',
                'Лица нуждающихся в трудоустройстве',
                'Занятые в неформальном секторе',
                'Занятые в формальном секторе',
                'Всего трудовых ресурсов',
                'Физически неактивного населения',
                'Физически активного населения',
                'Всего занятых',
            ],
            datasets: [
                {
                    label: 'Votes',
                    data: [9600000, 1000000, 250000, 1000000, 4000000, 6000000, 3000000, 6000000, 5000000],
                    borderWidth: 2,
                    backgroundColor: [
                        '#1177D6',
                        '#F3F800',
                        '#D211D6',
                        '#00109F',
                        '#D65811',
                        '#7F11D6',
                        '#FFB801',
                        '#11BED6',
                        '#11D677',
                    ],
                }
            ]
        },
        options: {
            scales: {
                x: {
                    display: false,
                    title: {
                        display: false,
                    }
                },
                y: {
                    display: false,
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                }
            },
        }
    });



});